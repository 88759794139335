import React from "react";
import { Button } from "semantic-ui-react";
import "../orderDisplay.scss";

import { FiPrinter } from "@react-icons/all-files/fi/FiPrinter";
import { OrderItemButton, RevertStatusButton, DynamicButton } from "./OrderItemButton";
import { confirmNotification, SpringButton } from "../../../utils";
import { onlineOrderStatus, modalNames, KitchenOrderStatus } from "Constants";
import { useModal } from "Providers";
import { Header, Flex, Button as AtomsButton, Text } from "Atoms";
import { REPRINT_KITCHEN_ORDER, CANCEL_CATERING_ORDER, CANCEL_ONLINE_ORDER } from "GraphQLMutations";
import { useMutation } from "react-apollo";
import { OrderPopup } from "./OrderPopup";
const getStatusTxt = (viewLoadingMsg, KOthirdPartyDeliveryType, catering) => {
    if (catering) {
        return "Inväntar bekräftelse";
    } else if (viewLoadingMsg) {
        if (!!KOthirdPartyDeliveryType) {
            return "Inväntar leverans";
        } else {
            return "Bekräftar order";
        }
    }
};

export const OrderFooter = ({
    isFailedCardPayment,
    shopSettings,
    showCardColors,
    kitchenOrder,
    changeStatus,
    handleUpdateOnlineOrderStatus,
    onlineOrderNeedsConfirmation,
    hideOrders,
    toggleDimmer,
    viewLoadingMsg,
    lastMileHasError,
    handleFilterUnfinishedKitchenOrders,
    handleCloseConfirmPickupModal,
    catering,
    isCateringConfirmed,
    cardStyleZoom,
    ...rest
}) => {
    const {
        enableDoneCookingStatus,
        enableDonePreppingStatus,
        onlineSettings,
        enableKitchenPrinterOnline,
        enableKitchenPrinterPOS
    } = shopSettings;

    const { openModal } = useModal();
    const [printKitchenOrder] = useMutation(REPRINT_KITCHEN_ORDER);
    const [cancelOnlineOrder] = useMutation(CANCEL_ONLINE_ORDER);
    const [cancelCateringOrder] = useMutation(CANCEL_CATERING_ORDER);

    const onCancelCateringOrder = async () => {
        const { value } = await confirmNotification(
            "Avbryt cateringorder?",
            "Kunden kommer bli informerade via email att deras catering order är avbryten.",
            "Okej",
            "#E53E3E"
        );

        if (value) {
            cancelCateringOrder({
                variables: {
                    orderId: kitchenOrder.orderId
                }
            });
        }
    };
    const onCancelOnlineOrder = async () => {
        const { value } = await confirmNotification(
            `Vill du avbryta Order #${kitchenOrder.orderNo}?`,
            "Pengar kommer returneras till kund.",
            "Ja, avbryt",
            "red"
        );
        if (value) {
            const { data } = await cancelOnlineOrder({
                variables: { kitchenOrderId: kitchenOrder.id }
            });
            handleFilterUnfinishedKitchenOrders(data.cancelOnlineOrder.id);
        }
    };
    const onPrintKitchenOrder = async () => {
        const { value } = await confirmNotification(
            `Vill du skriva ut Order #${kitchenOrder.orderNo} igen?`,
            "",
            "Ja, skriv ut",
            "green"
        );
        if (value) {
            printKitchenOrder({ variables: { kitchenOrder } });
        }
    };
    const paddingBetweenButton = () => {
        if (kitchenOrder.status !== KitchenOrderStatus.PROCESSING) {
            if (kitchenOrder.status !== KitchenOrderStatus.WAITING) {
                return 4;
            } else {
                if (
                    (kitchenOrder.onlineOrderStatus === onlineOrderStatus.CONFIRMED && enableKitchenPrinterOnline) ||
                    enableKitchenPrinterPOS
                ) {
                    return 2;
                } else {
                    return 0;
                }
            }
        } else {
            if (
                (kitchenOrder.onlineOrderStatus === onlineOrderStatus.CONFIRMED && enableKitchenPrinterOnline) ||
                enableKitchenPrinterPOS
            ) {
                return 2;
            } else {
                return 0;
            }
        }
    };
    return (
        <div
            style={{ filter: isFailedCardPayment ? "blur(1px) brightness(0.3)" : "none" }}
            className={`OD-card-footer  ${onlineOrderNeedsConfirmation ? "notification-blink radius-bottom" : ""}`}
        >
            {onlineOrderNeedsConfirmation && !(lastMileHasError || viewLoadingMsg) && !catering ? (
                <div style={{ justifyContent: "start", display: "flex", alignContent: "center" }}>
                    <SpringButton
                        customOnClick={e => {
                            e.stopPropagation();
                            openModal(modalNames.CONFIRM_ORDER_PICKUP_TIME_MODAL, {
                                kitchenOrder,
                                handleUpdateOnlineOrderStatus,
                                onlineSettings,
                                showCardColors,
                                onlineOrderNeedsConfirmation,
                                handleCloseConfirmPickupModal,
                                ...rest
                            });
                        }}
                        className="OD-green-button online-buttons ui large button"
                    >
                        Hantera
                    </SpringButton>
                </div>
            ) : null}
            {(kitchenOrder.onlineOrderStatus === onlineOrderStatus.CONFIRMED ||
                !kitchenOrder.online ||
                kitchenOrder.onlineOrderStatus === onlineOrderStatus.ERROR_DELIVERY_CREATE) && (
                    <div
                        style={{
                            justifyContent: "start",
                            display: "flex"
                        }}
                    >
                        {!hideOrders ? (
                            <>
                                <OrderItemButton
                                    key={1}
                                    showCardColors={showCardColors}
                                    orderStatus={kitchenOrder.status}
                                    changeStatus={changeStatus}
                                    orderId={kitchenOrder.id}
                                    enableDoneCookingStatus={enableDoneCookingStatus}
                                    enableDonePreppingStatus={enableDonePreppingStatus}
                                    viewLoadingMsg={viewLoadingMsg}
                                    lastMileHasError={lastMileHasError}
                                    handleFilterUnfinishedKitchenOrders={handleFilterUnfinishedKitchenOrders}
                                />
                                {kitchenOrder.online ? (
                                    <OrderPopup
                                        cardStyleZoom={cardStyleZoom}
                                        kitchenOrder={kitchenOrder}
                                        paddingBetweenButton={paddingBetweenButton}
                                        onPrintKitchenOrder={onPrintKitchenOrder}
                                        onCancelOnlineOrder={onCancelOnlineOrder}
                                        onCancelCateringOrder={onCancelCateringOrder}
                                        enableKitchenPrinterOnline={enableKitchenPrinterOnline}
                                        enableKitchenPrinterPOS={enableKitchenPrinterPOS}
                                        showCardColors={showCardColors}
                                        changeStatus={changeStatus}
                                        enableDoneCookingStatus={enableDoneCookingStatus}
                                        cancelButton
                                        catering={catering}
                                    />
                                ) : (kitchenOrder.onlineOrderStatus === onlineOrderStatus.CONFIRMED &&
                                    enableKitchenPrinterOnline) ||
                                    enableKitchenPrinterPOS ? (
                                    kitchenOrder.status !== KitchenOrderStatus.PROCESSING ? (
                                        <OrderPopup
                                            cardStyleZoom={cardStyleZoom}
                                            kitchenOrder={kitchenOrder}
                                            paddingBetweenButton={paddingBetweenButton}
                                            onPrintKitchenOrder={onPrintKitchenOrder}
                                            onCancelOnlineOrder={onCancelOnlineOrder}
                                            onCancelCateringOrder={onCancelCateringOrder}
                                            enableKitchenPrinterOnline={enableKitchenPrinterOnline}
                                            enableKitchenPrinterPOS={enableKitchenPrinterPOS}
                                            showCardColors={showCardColors}
                                            changeStatus={changeStatus}
                                            enableDoneCookingStatus={enableDoneCookingStatus}
                                            catering={catering}
                                        />
                                    ) : (
                                        <AtomsButton
                                            mb={paddingBetweenButton()}
                                            size="lg"
                                            onClick={onPrintKitchenOrder}
                                            fontFamily="Lato, sans-serif"
                                            backgroundColor="#e0e1e2"
                                            color={"rgba(0, 0, 0, 0.6)"}
                                        >
                                            <FiPrinter size={18} />
                                            <Text ml={1}>Skriv ut</Text>
                                        </AtomsButton>
                                    )
                                ) : (
                                    <RevertStatusButton
                                        key={2}
                                        showCardColors={showCardColors}
                                        orderStatus={kitchenOrder.status}
                                        changeStatus={changeStatus}
                                        orderId={kitchenOrder.id}
                                        enableDoneCookingStatus={enableDoneCookingStatus}
                                        enableDonePreppingStatus={enableDonePreppingStatus}
                                    />
                                )}
                            </>
                        ) : (
                            <DynamicButton
                                doFunction={() => toggleDimmer(kitchenOrder)}
                                text="Dölj"
                                showCardColors={showCardColors}
                            />
                        )}
                    </div>
                )}

            {viewLoadingMsg || !isCateringConfirmed ? (
                <Flex flex="1" alignItems="center" justify="flex-end">
                    <Header
                        marginBottom={0}
                        fontSize="2xl"
                        color={viewLoadingMsg ? "primary" : ""}
                        p={2}
                        as="mark"
                        rounded="md"
                        mr={2}
                    >
                        {getStatusTxt(viewLoadingMsg, kitchenOrder.thirdPartyDeliveryType, catering)}
                        ...
                    </Header>
                </Flex>
            ) : null}
            {kitchenOrder.orderNo > 0 && <h2>#{kitchenOrder.orderNo}</h2>}
        </div>
    );
};
