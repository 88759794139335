import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { FiPrinter } from "@react-icons/all-files/fi/FiPrinter";
import { FiTrash } from "@react-icons/all-files/fi/FiTrash";
import { FiSend } from "@react-icons/all-files/fi/FiSend";

import { Modal, ModalHeader, ModalBody, ModalCloseBtn } from "Molecules";
import { Flex, PseudoBox, PseudoBoxProps, Header, Button, Switch } from "Atoms";
import { IModalContext } from "Providers";
import { OrderDisplayContext } from "../orderDisplayTypes";
import { confirmOrCancelNotification } from "TempUtils";
import { orderDisplayConstants, modalNames, FAVORITE_SCREEN, LOCAL_STORAGE_CONSTANTS } from "Constants";
import { EMPTY_PRINTER_QUEUE, TEST_ORDER_PRINTER, UPDATE_CLOSE_ONLINE_ORDERS } from "GraphQLMutations";
import { TEST_ORDER_WEBSOCKET } from "../../../../graphql/mutations/KitchenOrderMutations";
import { useLocalStorage } from "Hooks";

const {
    HIDE_ONLINE_ORDERS,
    HIDE_READY_FOR_PICKUP,
    HIDE_CURRENT_ORDERS,
    SHOW_CARD_COLOR,
    ONLY_DISPLAY_DRIVE_THROUGH,
    DISPLAY_PRODUCT_DESCRIPTION,
    HIDE_ORDERS,
    HIDE_DONE_COOKING,
    HIDE_DONE_PREPPING,
    PLING_ON_NEW_KITCHEN_ORDER,
    HIDE_CATERING_ORDERS
} = orderDisplayConstants;

export const ListItem: React.FC<PseudoBoxProps> = ({ color, ...props }) => <PseudoBox as="li" {...props} />;

type Props = {
    shopId: string;
    orderDisplayState: OrderDisplayContext;
    toggleSelectedState: (target: string, overridenVal?: any) => void;
    shopSettings: {
        onlineSettings: {
            closeOnlineOrders: boolean;
        };
        enableDoneCookingStatus: boolean;
        enableDonePreppingStatus: boolean;
        kitchenPrinterEnabled: boolean;
        enableCatering: boolean;
    };
    openHideRefCategoryModal: () => void;
};

export const OrderAdvancedSettingsModal: React.FC<IModalContext<Props>> = ({
    modalContent: { shopId, orderDisplayState, toggleSelectedState, shopSettings, openHideRefCategoryModal },
    closeModal
}) => {
    const [
        {
            hideOnlineContainer,
            hideCateringContainer,
            hideReadyForPickupContainer,
            hideCurrentOrdersContainer,
            showCardColors,
            displayProductDescriptions,
            onlyDisplayDriveThrough,
            hideOrders,
            hideDoneCookingContainer,
            hideDonePreppingContainer,
            onlineIsClosed,
            plingOnNewKitchenOrder
        },
        setOrderDisplayStateCopy
    ] = useState(orderDisplayState);

    const [updateCloseOnlineOrders] = useMutation(UPDATE_CLOSE_ONLINE_ORDERS);
    const [testPrint] = useMutation(TEST_ORDER_PRINTER);
    const [emptyPrinterQueue] = useMutation(EMPTY_PRINTER_QUEUE);
    const [sendTestOrder] = useMutation(TEST_ORDER_WEBSOCKET);

    const [favoriteScreen, setFavoriteScreen] = useLocalStorage(LOCAL_STORAGE_CONSTANTS.FAVORITE_SCREEN);

    const getHeaderText = (stateToToggle: string, stateValue: boolean) => {
        const toggleActions = [HIDE_ORDERS, SHOW_CARD_COLOR, PLING_ON_NEW_KITCHEN_ORDER];
        let actionText = "";
        if (toggleActions.includes(stateToToggle)) {
            actionText = stateValue ? "stänga av" : "slå på";
        } else {
            actionText = stateValue ? "glömma" : "visa";
        }

        return `Är du säker på att du vill ${actionText}?`;
    };

    const confirmBeforeToggle = async (stateToToggle: string, stateValue: boolean) => {
        const actionText = getHeaderText(stateToToggle, stateValue);
        const { value } = await confirmOrCancelNotification("Bekräfta", actionText);
        if (value) {
            toggleSelectedState(stateToToggle);
            setOrderDisplayStateCopy(curr => ({
                ...curr,
                [stateToToggle]: !(curr as any)[stateToToggle]
            }));
        }
    };

    const confirmCloseBeforeToggle = async (stateValue: boolean) => {
        const action = stateValue ? "stänga" : "aktivera";
        const { value } = await confirmOrCancelNotification(
            "Bekräfta",
            `Är du säker på att du vill ${action} onlinebeställningar`
        );
        if (value) {
            const resp = await updateCloseOnlineOrders({ variables: { shopId, enable: stateValue } });
            setOrderDisplayStateCopy(curr => ({
                ...curr,
                onlineIsClosed: stateValue
            }));
            toggleSelectedState("onlineIsClosed", resp.data.updateCloseOnlineOrders);
        }
    };

    const confirmDtMode = async (stateToToggle: string, stateValue: boolean) => {
        const action = stateValue ? "normal läge" : "drive through läge";
        const { value } = await confirmOrCancelNotification(
            "Bekräfta",
            `Är du säker på att du vill ställa om Orderskärmen till ${action}?`
        );
        if (value) {
            toggleSelectedState(stateToToggle);

            setOrderDisplayStateCopy(curr => ({
                ...curr,
                [stateToToggle]: !(curr as any)[stateToToggle]
            }));
        }
    };

    const favoriteScreenHandler = () => {
        const newFavorite =
            favoriteScreen === FAVORITE_SCREEN.ORDER_DISPLAY ? FAVORITE_SCREEN.NONE : FAVORITE_SCREEN.ORDER_DISPLAY;
        setFavoriteScreen(newFavorite);
    };

    const items = [
        {
            text: "Stäng online",
            value: onlineIsClosed,
            handler: () => confirmCloseBeforeToggle(!onlineIsClosed)
        },
        {
            text: "Catering",
            value: !hideCateringContainer,
            handler: () => confirmBeforeToggle(HIDE_CATERING_ORDERS, !hideCateringContainer),
            show: shopSettings.enableCatering
        },
        {
            text: "Visa onlineflik",
            value: !hideOnlineContainer,
            handler: () => confirmBeforeToggle(HIDE_ONLINE_ORDERS, !hideOnlineContainer)
        },
        {
            text: "Upphämtning",
            value: !hideReadyForPickupContainer,
            handler: () => confirmBeforeToggle(HIDE_READY_FOR_PICKUP, !hideReadyForPickupContainer)
        },
        {
            text: "Färdigpreppade",
            value: !hideDonePreppingContainer,
            handler: () => confirmBeforeToggle(HIDE_DONE_PREPPING, !hideDonePreppingContainer),
            show: shopSettings.enableDonePreppingStatus
        },
        {
            text: "Färdiglagade",
            value: !hideDoneCookingContainer,
            handler: () => confirmBeforeToggle(HIDE_DONE_COOKING, !hideDoneCookingContainer),
            show: shopSettings.enableDoneCookingStatus
        },
        {
            text: "Aktuella",
            value: !hideCurrentOrdersContainer,
            handler: () => confirmBeforeToggle(HIDE_CURRENT_ORDERS, !hideCurrentOrdersContainer)
        },
        {
            text: "Kan dölja ordrar",
            value: hideOrders,
            handler: () => confirmBeforeToggle(HIDE_ORDERS, hideOrders)
        },
        {
            text: "Aktuell order ljudnotis",
            value: plingOnNewKitchenOrder,
            handler: () => confirmBeforeToggle(PLING_ON_NEW_KITCHEN_ORDER, plingOnNewKitchenOrder)
        },
        {
            text: "Färger",
            value: showCardColors,
            handler: () => confirmBeforeToggle(SHOW_CARD_COLOR, showCardColors)
        },
        {
            text: "Produktbeskrivning",
            value: displayProductDescriptions,
            handler: () => confirmBeforeToggle(DISPLAY_PRODUCT_DESCRIPTION, displayProductDescriptions)
        },
        {
            text: "Drive Through",
            value: onlyDisplayDriveThrough,
            handler: () => confirmDtMode(ONLY_DISPLAY_DRIVE_THROUGH, onlyDisplayDriveThrough)
        },
        {
            text: "Favoritskärm",
            value: favoriteScreen === FAVORITE_SCREEN.ORDER_DISPLAY,
            handler: favoriteScreenHandler
        }
    ];

    return (
        <Modal
            id="orderAdvancedSettingsModal"
            open={true}
            isScrolling
            dimmer={{ inverted: true, strength: "0.5" }}
            rounded="md"
            size="xs"
            onClose={() => closeModal(modalNames.ORDER_ADVANCED_SETTINGS_MODAL)}
        >
            <ModalHeader>
                <Header as="h3" fontSize="3xl">
                    Avancerade inställningar
                </Header>
                <ModalCloseBtn onClick={() => closeModal(modalNames.ORDER_ADVANCED_SETTINGS_MODAL)} />
            </ModalHeader>
            <ModalBody>
                <Flex height="auto">
                    <ul style={{ width: "100%", listStyle: "none" }}>
                        {items.map(({ text, value, handler, show }) => {
                            if (show === undefined || !!show) {
                                return (
                                    <ListItem key={text} style={{ marginBottom: "1rem" }}>
                                        <Flex justify="space-between" align="center">
                                            <Header fontSize="2xl" margin="0">
                                                {text}
                                            </Header>
                                            <Switch
                                                defaultChecked={!!value}
                                                themeColor="green"
                                                size="smalltouch"
                                                onClick={handler}
                                            />
                                        </Flex>
                                    </ListItem>
                                );
                            }
                        })}
                        <ListItem key="Visningsalternativ" style={{ marginBottom: "1rem" }}>
                            <Flex justify="space-between" align="center">
                                <Header fontSize="2xl" margin="0">
                                    Visningsalternativ
                                </Header>
                                <Button
                                    themeColor="green"
                                    mr={3}
                                    onClick={() => {
                                        closeModal(modalNames.ORDER_ADVANCED_SETTINGS_MODAL);
                                        openHideRefCategoryModal();
                                    }}
                                >
                                    Ändra
                                </Button>
                            </Flex>
                        </ListItem>
                    </ul>
                </Flex>

                {shopSettings.kitchenPrinterEnabled && (
                    <>
                        <Header as="h3" fontSize="3xl">
                            Köksskrivare
                        </Header>
                        <Button
                            leftIcon={FiPrinter}
                            mr={4}
                            size="lg"
                            onClick={() => testPrint({ variables: { shopId } })}
                        >
                            Test utskrift
                        </Button>
                        <Button
                            leftIcon={FiTrash}
                            size="lg"
                            onClick={() => emptyPrinterQueue({ variables: { shopId } })}
                        >
                            Töm utskriftskö
                        </Button>
                    </>
                )}
                <Header as="h3" fontSize="3xl">
                    Test
                </Header>
                <Button
                    leftIcon={FiSend}
                    size="lg"
                    onClick={() => setTimeout(() => sendTestOrder({ variables: { shopId } }), 1500)}
                    mb={2}
                >
                    Skicka testorder
                </Button>
            </ModalBody>
        </Modal>
    );
};
