import React from "react";

import "../orderDisplay.scss";
import { Header, Text, NewList, NewListItem, Flex, NewGrid, NewDivider } from "Atoms";
import { AddonsCartItem } from "../../poses/shared/components";

const renderOrderMods = modifications => {
    if (!modifications) return null;

    const flavoursExists = modifications.flavours && modifications.flavours.length > 0;
    const sizesExists = modifications.sizes && modifications.sizes.length > 0;

    const flavourName = flavoursExists && modifications.flavours[0].name;
    const sizeName = sizesExists && modifications.sizes[0].name;

    const flavourNameExists = flavourName && flavourName.trim();
    const sizeNameExists = sizeName && sizeName.trim();

    if (!flavourNameExists && !sizeNameExists) return null;

    return (
        <NewList listStyle="none" marginLeft={4}>
            {flavourNameExists && (
                <NewListItem>
                    <Flex whiteSpace="pre-wrap">
                        <Text margin={0} fontWeight="bold">
                            {"Smak: "}
                        </Text>
                        <Text margin={0}>{modifications.flavours[0].name}</Text>
                    </Flex>
                </NewListItem>
            )}
            {sizeNameExists && (
                <NewListItem>
                    <Flex whiteSpace="pre-wrap">
                        <Text margin={0} fontWeight="bold">
                            {"Storlek: "}
                        </Text>
                        <Text margin={0}>{modifications.sizes[0].name}</Text>
                    </Flex>
                </NewListItem>
            )}
        </NewList>
    );
};

const renderOrderBundleItems = (selectedBundleProductItems, showCardColors) => (
    <NewList listStyle="none" marginLeft={4}>
        {selectedBundleProductItems.map((bundleProdItem, i) => (
            <NewListItem key={i}>
                <Text margin={0} fontWeight={showCardColors ? "bold" : "normal"}>
                    {bundleProdItem.name}
                </Text>
                {renderOrderMods(bundleProdItem.modifications)}
                <AddonsCartItem marginLeft={2} addons={bundleProdItem.addons} hidePrice />
            </NewListItem>
        ))}
    </NewList>
);

const OrderProductsList = ({ orderProducts, showCardColors, refCatHashMap, productDescriptionHashMap, isUberEats }) => {
    const findAddons = prod => {
        if (prod.addons && prod.addons.length > 0) {
            return true;
        } else if (prod.selectedBundleProductItems && prod.selectedBundleProductItems.length > 0) {
            return prod.selectedBundleProductItems.some(product => product.addons.length > 0);
        } else {
            return false;
        }
    };
    const checkOrderToSplit = (acc, prod) => {
        if (prod.quantity > 1 && findAddons(prod)) {
            let newOrder = { ...prod };
            newOrder.quantity = 1;
            for (let i = 0; i < prod.quantity; i++) {
                acc.push(newOrder);
            }
        } else {
            acc.push(prod);
        }
        return acc;
    };
    const _orderProducts = orderProducts.reduce(checkOrderToSplit, []);

    return _orderProducts
        .sort((a, b) => {
            if (refCatHashMap[a.refProductCategoryId] && refCatHashMap[b.refProductCategoryId]) {
                return (
                    refCatHashMap[a.refProductCategoryId].sortOrder - refCatHashMap[b.refProductCategoryId].sortOrder
                );
            }
            return 0;
        })
        .map((orderProd, i) => {
            let productDescription = null;
            if (productDescriptionHashMap) {
                const idToUse = orderProd.refProductId ? orderProd.refProductId : orderProd.refBundleProductId;
                productDescription = productDescriptionHashMap[idToUse]
                    ? productDescriptionHashMap[idToUse].description
                    : null;
            }
            return (
                <React.Fragment key={i}>
                    <Flex alignItems="top" paddingY={4}>
                        <Header fontWeight="bold" fontSize="4xl" margin={0} whiteSpace="nowrap">
                            {orderProd.quantity} x
                        </Header>
                    </Flex>
                    <Flex alignItems="center" paddingY={4}>
                        <NewGrid
                            color={showCardColors && !isUberEats ? "#333333" : "#FFF"}
                            marginLeft={4}
                            paddingLeft={3}
                            width="100%"
                            borderLeft="1px solid rgba(200, 200, 200, 0.35)"
                        >
                            <Text margin={0} fontWeight={showCardColors ? "bold" : "normal"} fontSize="2xl">
                                {orderProd.name}
                            </Text>
                            {orderProd.modifications && renderOrderMods(orderProd.modifications)}
                            <AddonsCartItem marginLeft={2} addons={orderProd.addons} hidePrice />
                            {orderProd.selectedBundleProductItems &&
                                renderOrderBundleItems(orderProd.selectedBundleProductItems, showCardColors)}
                            {productDescription && (
                                <Flex
                                    flexDirection="column"
                                    backgroundColor={showCardColors ? "#e8d9c5" : "#4d4a60"}
                                    whiteSpace="pre-line"
                                    wordBreak="break-word"
                                    borderRadius="8px"
                                    textAlign="center"
                                    padding={3}
                                    marginY={2}
                                >
                                    <Text fontWeight="bold" margin={0}>
                                        Beskrivning
                                    </Text>
                                    <Text margin={0}>{productDescription}</Text>
                                </Flex>
                            )}
                            {orderProd.comment && orderProd.comment !== "NA" && (
                                <Flex
                                    color={showCardColors ? "#333333" : "#FFF"}
                                    flexDirection="column"
                                    backgroundColor={showCardColors ? "#e8d9c5" : "#4d4a60"}
                                    whiteSpace="pre-line"
                                    wordBreak="break-word"
                                    borderRadius="8px"
                                    textAlign="center"
                                    padding={3}
                                    marginY={2}
                                >
                                    <Text fontWeight="bold" margin={0}>
                                        Kommentar
                                    </Text>
                                    <Text margin={0}>{orderProd.comment}</Text>
                                </Flex>
                            )}
                        </NewGrid>
                    </Flex>
                    <NewDivider color="gray.400" gridColumn="span 2" border="" opacity={0.35} />
                </React.Fragment>
            );
        });
};

export const OrderProducts = ({
    orderProducts,
    showCardColors,
    refCatHashMap,
    productDescriptionHashMap,
    onlineOrderNeedsConfirmation,
    isUberEats,
    ...rest
}) => (
    <NewGrid
        className={`OD-order-list-container ${onlineOrderNeedsConfirmation ? "notification-blink" : ""}`}
        padding={4}
        margin={0}
        gridTemplateColumns="auto 1fr"
        fontSize={rest.fontSize ? rest.fontSize : ""}
    >
        <OrderProductsList
            orderProducts={orderProducts}
            showCardColors={showCardColors}
            refCatHashMap={refCatHashMap}
            productDescriptionHashMap={productDescriptionHashMap}
            isUberEats={isUberEats}
        />
    </NewGrid>
);
