import { useState } from "react";
import { Checkbox, List } from "semantic-ui-react";
import React from "react";

export const CategoryProductCheckbox = ({
    products,
    hiddenCategories,
    hiddenProducts,
    toggleHiddenCategory,
    toggleHiddenProduct,
    cat,
    disabledProdCatIdsSet,
    selectedFromStart
}) => {
    const [categoryExpanded, setCategoryExpanded] = useState(false);
    const categoryProducts = products.filter(prod => prod.refProductCategoryId === cat.id);
    const hasSelectedProduct = categoryProducts.some(prod => hiddenProducts.includes(prod.id));
    const categoryIsSelected = hiddenCategories.includes(cat.id);

    const categoryLabel = hasSelectedProduct ? `${cat.name} - finns valda produkter` : cat.name;

    return (
        <React.Fragment key={cat.id}>
            <List.Item style={{ backgroundColor: hasSelectedProduct ? "#c8e3c8" : "" }}>
                <List.Icon
                    className={categoryProducts && categoryProducts.length > 0 ? "" : "emptyCategory"}
                    name={categoryExpanded ? "minus" : "plus"}
                    onClick={() => setCategoryExpanded(!categoryExpanded)}
                />
                <List.Content>
                    <Checkbox
                        disabled={
                            disabledProdCatIdsSet && disabledProdCatIdsSet.has(cat.id) && !selectedFromStart.has(cat.id)
                        }
                        id={cat.id}
                        label={categoryLabel}
                        defaultChecked={categoryIsSelected}
                        onChange={(_, { checked }) => toggleHiddenCategory(cat.id, checked)}
                    />
                </List.Content>
            </List.Item>
            {categoryExpanded &&
                categoryProducts.map((prod, idx) => (
                    <List.Item key={cat.id + idx} style={{ paddingLeft: "40px" }}>
                        <Checkbox
                            disabled={
                                (disabledProdCatIdsSet &&
                                    disabledProdCatIdsSet.has(prod.id) &&
                                    !selectedFromStart.has(prod.id)) ||
                                categoryIsSelected ||
                                (disabledProdCatIdsSet && disabledProdCatIdsSet.has(cat.id))
                            }
                            label={prod.name}
                            defaultChecked={hiddenProducts.includes(prod.id)}
                            onChange={(_, { checked }) => toggleHiddenProduct(prod.id, checked)}
                        />
                    </List.Item>
                ))}
        </React.Fragment>
    );
};
