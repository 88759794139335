import React from "react";
import { FiPrinter } from "@react-icons/all-files/fi/FiPrinter";
import { FiX } from "@react-icons/all-files/fi/FiX";
import { MdMore } from "@react-icons/all-files/md/MdMore";
import { Button, Popup } from "semantic-ui-react";

import { Flex, Button as AtomsButton, Text } from "Atoms";
import { onlineOrderStatus } from "Constants";
import { RevertStatusButton } from "./OrderItemButton";

type Props = {
    cardStyleZoom: number;
    kitchenOrder: any;
    paddingBetweenButton: any;
    onPrintKitchenOrder: any;
    onCancelOnlineOrder: ()=>void;
    onCancelCateringOrder: ()=>void;
    enableKitchenPrinterOnline: boolean;
    enableKitchenPrinterPOS: boolean;
    showCardColors: any;
    changeStatus: any;
    enableDoneCookingStatus: any;
    cancelButton?: boolean;
    catering?: boolean
};
export const OrderPopup: React.FC<Props> = ({
    cardStyleZoom,
    kitchenOrder,
    paddingBetweenButton,
    onPrintKitchenOrder,
    onCancelOnlineOrder,
    onCancelCateringOrder,
    enableKitchenPrinterOnline,
    enableKitchenPrinterPOS,
    showCardColors,
    changeStatus,
    enableDoneCookingStatus,
    cancelButton,
    catering,

}) => {
    const onlineStatusForCancelButton = () => {
        if (cancelButton) {
            return (
                (kitchenOrder.onlineOrderStatus === onlineOrderStatus.CONFIRMED && enableKitchenPrinterOnline) ||
                enableKitchenPrinterPOS
            );
        } else return true;
    };

    return (
        <Popup
            trigger={
                <AtomsButton className="rotated" p={5} size="lg" color={"rgba(0, 0, 0, 0.6)"}>
                    <MdMore size={20} />
                </AtomsButton>
            }
            position="right center"
            on="click"
            style={{
                backgroundColor: "#2D3748",
                zoom: cardStyleZoom ? `${cardStyleZoom}%` : "100%"
            }}
        >
            <Flex>
                <Button.Group vertical>
                    {onlineStatusForCancelButton() && (
                        <AtomsButton
                            mb={paddingBetweenButton()}
                            size="lg"
                            onClick={onPrintKitchenOrder}
                            fontFamily="Lato, sans-serif"
                            backgroundColor="#e0e1e2"
                            color={"rgba(0, 0, 0, 0.6)"}
                        >
                            <FiPrinter size={18} />
                            <Text ml={1}> Skriv ut</Text>
                        </AtomsButton>
                    )}
                    <RevertStatusButton
                        key={2}
                        showCardColors={showCardColors}
                        orderStatus={kitchenOrder.status}
                        changeStatus={changeStatus}
                        orderId={kitchenOrder.id}
                        enableDoneCookingStatus={enableDoneCookingStatus}
                        enableDonePreppingStatus={enableDoneCookingStatus}
                        text=" Ångra"
                    />
                    {cancelButton && (
                        <AtomsButton
                            themeColor="red"
                            size="lg"
                            mt={paddingBetweenButton()}
                            onClick={catering ? onCancelCateringOrder : onCancelOnlineOrder}
                        >
                            <FiX size={20} /> Avbryt
                        </AtomsButton>
                    )}
                </Button.Group>
            </Flex>
        </Popup>
    );
};
