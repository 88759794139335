import React from "react";
import { FaComment } from "@react-icons/all-files/fa/FaComment";

import { Box, Text, Flex } from "Atoms";
import "../orderDisplay.scss";

export const OrderComment = ({ comment, showCardColors, onlineOrderNeedsConfirmation, ...rest }) => {
    if (!comment) return null;
    if (!comment.trim()) return null;
    const notificationBlink = onlineOrderNeedsConfirmation ? "notification-blink" : "";
    return (
        <Box className={`${notificationBlink}`} px="1.5em" py="1em" fontWeight={showCardColors ? "bold" : "normal"}>
            <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                color={showCardColors ? "#333333" : "#FFF"}
                fontSize={rest.fontSize ? rest.fontSize : ""}
                py="1em"
                px="0"
                bg={showCardColors ? "#e8d9c5" : "#4d4a60"}
                borderRadius="8px"
            >
                <Flex justifyContent="center" alignItems="center">
                    <Box as={FaComment} mr={2} /> <Text>ORDERKOMMENTAR </Text>
                </Flex>
                <Text mt={2}>{comment}</Text>
            </Flex>
        </Box>
    );
};
