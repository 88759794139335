import { get } from "lodash";

import { setIn } from "Utils";

const decreaseQuantityByOne = (product: any, path: string) => {
    const currQuantity = get(product, path);
    return setIn(product, path, currQuantity - 1);
};
const increaseQuantityByOne = (product: any, path: string) => {
    const currQuantity = get(product, path);
    return setIn(product, path, currQuantity + 1);
};
const setQuantityToOne = (product: any, path: string) => {
    return setIn(product, path, 1);
};

const removeProductFromArray = (array: any[], product: any) => {
    return array.filter(el => {
        const elId = el.hashedId ? el.hashedId : el.id;
        const productId = product.hashedId ? product.hashedId : product.id;
        return elId !== productId;
    });
};
const addProductToArray = (array: any[], product: any) => {
    return array.concat(product);
};

const productArrayHandler = (array: any[], product: any, path: string, handler: Function) => {
    return array.map(el => {
        const elId = el.hashedId ? el.hashedId : el.id;
        const productId = product.hashedId ? product.hashedId : product.id;
        if (elId === productId) {
            return handler(el, path);
        }
        return el;
    });
};

const onProductDecrease = (array: any[], product: any, path: string = "quantity") => {
    const shouldRemove = get(product, path) === 1;

    if (shouldRemove) {
        return removeProductFromArray(array, product);
    } else {
        return productArrayHandler(array, product, path, decreaseQuantityByOne);
    }
};

const onProductIncrease = (array: any[], product: any, path: string = "quantity") => {
    const foundToRefundProduct = array.find(el => {
        const elId = el.hashedId ? el.hashedId : el.id;
        const productId = product.hashedId ? product.hashedId : product.id;
        return elId === productId;
    });

    if (!foundToRefundProduct) {
        return addProductToArray(array, setQuantityToOne(product, path));
    } else {
        return productArrayHandler(array, product, path, increaseQuantityByOne);
    }
};

export { onProductDecrease, onProductIncrease };
