import React from "react";
import { useMutation, useQuery } from "react-apollo";
import { Icon } from "semantic-ui-react";

import { Box, Flex, Header, PseudoBoxProps, HeaderProps, PseudoBox, Button } from "Atoms";
import { useModal, usePunchClock } from "Providers";
import { Drawer, DrawerContent, DrawerOverlay, DrawerBody } from "Organisms";
import { modalNames, ONE_MINUTE } from "Constants";
import { useSwitch } from "Hooks";
import { toggleFullScreen } from "Utils";
import {
    UPDATE_PAUSE_ONLINE,
    UPDATE_ENABLE_EAT_HERE,
    UPDATE_PAUSE_HOME_DELIVERY,
    UPDATE_RUSH_HOUR,
    SAVE_DELIVERY_SERVICE_SETTING
} from "GraphQLMutations";
import {
    confirmOrCancelNotification,
    selectAndConfirmOrCancelNotification,
    confirmOrCancelNotificationWithRadio
} from "TempUtils";
import { OrderDisplayContext } from "../orderDisplayTypes";
import { IoMdPause } from "@react-icons/all-files/io/IoMdPause";
import { IoMdPlay } from "@react-icons/all-files/io/IoMdPlay";
import { GET_DELIVERY_SERVICES } from "GraphQLQueries";
import { ThirdPartyDeliveryService } from "Types";
import { useQoplaStore } from "Stores";

type OnlineServiceTimeouts = {
    [key: string]: {
        isActive: boolean;
        minutes: string;
    };
};

const CustomRadioButton = ({ value, ...rest }: any) => {
    return <Button size="sm" value={value} role="radio" {...rest} />;
};

type Props = {
    openDrawer: boolean;
    kitchenPrinterEnabled: boolean;
    onCloseDrawer: () => void;
    orderDisplayState: OrderDisplayContext;
    toggleSelectedState: (target: string, overridenVal?: any) => void;
    reducingCardStyle: () => void;
    zoomingCardStyle: () => void;
    cardStyleZoom: number;
    openManageTemporariesModal: () => void;
    openHideRefCategoryModal: () => void;
    enableNotificationSound: () => void;
    mobileSoundNotificationsEnabled: boolean;
    setPausedIntervals: Function;
};

const OrderSideBarHeader: React.FC<{ text: string } & HeaderProps> = ({ text, ...rest }) => (
    <Header size="xl" textAlign="center" color="white" fontWeight="normal" children={text} {...rest} />
);

const OrderSidebarItem: React.FC<PseudoBoxProps> = props => (
    <PseudoBox
        pt={8}
        pb={8}
        width="100%"
        _hover={{ cursor: "pointer", backgroundColor: "gray.800", borderRadius: "lg" }}
        {...props}
    />
);

export const OrderSidebar: React.FC<Props> = ({
    mobileSoundNotificationsEnabled,
    enableNotificationSound,
    openDrawer,
    kitchenPrinterEnabled,
    onCloseDrawer,
    orderDisplayState,
    toggleSelectedState,
    reducingCardStyle,
    zoomingCardStyle,
    cardStyleZoom,
    openManageTemporariesModal,
    openHideRefCategoryModal,
    setPausedIntervals
}) => {
    const { openModal } = useModal();
    const { open, onToggle } = useSwitch();
    const { onOpenPunchClock } = usePunchClock();

    const { selectedShop } = useQoplaStore();
    const id = selectedShop?.id;
    const shop = selectedShop;
    const settings = shop?.settings;
    const onlineSettings = settings?.onlineSettings;
    const homeDeliverySettings = settings?.homeDeliverySettings;
    const cateringSettings = settings?.cateringSettings;
    const enableDonePreppingStatus = settings?.enableDonePreppingStatus;
    const enableDoneCookingStatus = settings?.enableDoneCookingStatus;

    const [updatePauseOnline] = useMutation(UPDATE_PAUSE_ONLINE);
    const [updateEnableEatHere] = useMutation(UPDATE_ENABLE_EAT_HERE);
    const [updatePauseHomeDelivery] = useMutation(UPDATE_PAUSE_HOME_DELIVERY);
    const [updateRushHour] = useMutation(UPDATE_RUSH_HOUR);
    const [saveDeliveryServiceSetting] = useMutation(SAVE_DELIVERY_SERVICE_SETTING);

    const { data } = useQuery(GET_DELIVERY_SERVICES, {
        fetchPolicy: "network-only",
        variables: {
            shopId: id
        }
    });

    const enableOnlineOrders = onlineSettings ? onlineSettings.enableOnlineOrders : false;
    const enableHomeDelivery = homeDeliverySettings ? homeDeliverySettings.enableHomeDelivery : false;
    const enableCatering = cateringSettings ? cateringSettings.enableCatering : false;
    const hasThirdPartyServices = data?.getThirdPartyDeliveryServiceSettings?.length > 0;

    const onOpenPauseOnlineAlert = async () => {
        const headerText = `${orderDisplayState.pauseOnlineOrders ? "Starta" : "Pausa"} online?`;
        const inputOptions = {
            "30": "30 MIN",
            "60": "60 MIN"
        };
        const inputValue = "30";

        const { value } = orderDisplayState.pauseOnlineOrders
            ? await confirmOrCancelNotification(headerText, `Är du säker på att du vill ${headerText}`, "Ja", "#38A169")
            : await confirmOrCancelNotificationWithRadio(
                  headerText,
                  `Är du säker på att du vill ${headerText}`,
                  inputOptions,
                  inputValue,
                  "Ja",
                  "#38A169"
              );

        return value;
    };

    const handlePauseQoplaOnline = async (shopId: string, onlineServiceTimeouts: OnlineServiceTimeouts) => {
        const resp = await updatePauseOnline({
            variables: {
                shopId,
                pause: !onlineServiceTimeouts.QOPLA.isActive
            }
        });
        resp.data.updatePauseOnline && toggleSelectedState("pauseOnlineOrders", !onlineServiceTimeouts.QOPLA.isActive);
        setPausedIntervals([]);

        if (onlineServiceTimeouts) {
            const newDate = new Date();

            const arr = Object.entries(onlineServiceTimeouts).map(([serviceName, serviceInfo]) => {
                const foundPausedService = orderDisplayState.pausedServices?.find(
                    interval => interval.serviceName === serviceName
                );
                if (foundPausedService?.minutes === serviceInfo.minutes && !serviceInfo.isActive) {
                    return foundPausedService;
                } else {
                    const shouldSetDate = Number(serviceInfo.minutes) > 0;
                    const date = shouldSetDate
                        ? new Date(newDate.getTime() + Number(serviceInfo.minutes) * ONE_MINUTE).getTime()
                        : 0;

                    return {
                        serviceName,
                        minutes: serviceInfo.minutes,
                        date
                    };
                }
            });

            setPausedIntervals(arr);
        }

        return resp;
    };

    const handleSubmitDeliverySettings = async ({ thirdPartyDeliveryServices, onlineServiceTimeouts }: any) => {
        if (!!id) {
            await Promise.all([
                saveDeliveryServiceSetting({
                    variables: {
                        shopId: id,
                        thirdPartyDeliveryServices
                    }
                }),
                handlePauseQoplaOnline(id, onlineServiceTimeouts)
            ]);
        }
    };

    const handleUpdateOnlineServices = async (thirdPartyDeliveryServices: ThirdPartyDeliveryService[]) => {
        if (hasThirdPartyServices) {
            openModal(modalNames.ONLINE_SERVICE_MODAL, {
                thirdPartyDeliveryServices,
                pauseOnlineOrders: orderDisplayState.pauseOnlineOrders,
                saveDeliverySettings: handleSubmitDeliverySettings,
                pausedServices: orderDisplayState.pausedServices
            });
        } else {
            const value = await onOpenPauseOnlineAlert();
            if (value) {
                await updatePauseOnline({
                    variables: {
                        shopId: id,
                        pause: !orderDisplayState.pauseOnlineOrders
                    }
                });
                const hasPausedOnline = typeof value !== "boolean";
                const hasUnPausedOnline = !hasPausedOnline && orderDisplayState.pauseOnlineOrders;

                if (hasPausedOnline) {
                    const newDate = new Date();
                    const date = new Date(newDate.getTime() + Number(value) * ONE_MINUTE).getTime();
                    setPausedIntervals([{ serviceName: "QOPLA", minutes: value, date }]);
                }
                if (hasUnPausedOnline) {
                    setPausedIntervals([]);
                }
            }
        }
    };

    const handleUpdateRushHour = async () => {
        const headerText = `${!orderDisplayState.rushHour ? "Starta" : "Stoppa"} rusningstid?`;
        const { value } = !orderDisplayState.rushHour
            ? await selectAndConfirmOrCancelNotification(headerText, `Du kan välja att visa en uppskattad väntetid`)
            : await confirmOrCancelNotification(headerText, `Är du säker på att du vill ${headerText.toLowerCase()}`);
        if (value) {
            const resp = await updateRushHour({
                variables: {
                    shopId: id,
                    pause: !orderDisplayState.rushHour,
                    time: value && value !== true ? parseInt(value) : 0
                }
            });
            resp.data.updateRushHour && toggleSelectedState("rushHour", !orderDisplayState.rushHour);
        }
    };

    const handleUpdateHomeDelivery = async () => {
        const headerText = `${orderDisplayState.pauseHomeDelivery ? "Starta" : "Pausa"} hemleverans?`;
        const { value } = await confirmOrCancelNotification(headerText, `Är du säker på att du vill ${headerText}`);
        if (value) {
            const resp = await updatePauseHomeDelivery({
                variables: {
                    shopId: id,
                    pause: !orderDisplayState.pauseHomeDelivery
                }
            });
            resp.data.updatePauseHomeDelivery &&
                toggleSelectedState("pauseHomeDelivery", !orderDisplayState.pauseHomeDelivery);
        }
    };

    const handleEnableEatHere = async () => {
        const headerText = `${orderDisplayState.enableEatHere ? "Stoppa" : "Tillåt"} äta här?`;
        const { value } = await confirmOrCancelNotification(headerText, `Är du säker på att du vill ${headerText}`);
        if (value) {
            const resp = await updateEnableEatHere({
                variables: {
                    shopId: id,
                    enable: !orderDisplayState.enableEatHere
                }
            });
            resp.data.updateEnableEatHere && toggleSelectedState("enableEatHere", !orderDisplayState.enableEatHere);
            onCloseDrawer();
        }
    };

    return (
        <Box>
            <Drawer open={openDrawer} onClose={onCloseDrawer} size="md" duration={0}>
                <DrawerOverlay />
                <DrawerContent bg="gray.900" overflow="auto">
                    <DrawerBody>
                        <Flex direction="column" align="center" userSelect="none">
                            <OrderSidebarItem textAlign="center">
                                <Flex justifyContent={"space-between"} px={"7em"}>
                                    <Icon
                                        style={{ color: "white" }}
                                        size="big"
                                        name={open ? "compress" : "expand"}
                                        onClick={() => {
                                            toggleFullScreen(onToggle);
                                        }}
                                    />
                                    <Icon
                                        style={{ color: "white" }}
                                        size="big"
                                        name={"refresh"}
                                        onClick={() => {
                                            location.reload();
                                        }}
                                    />
                                    <Icon
                                        name={mobileSoundNotificationsEnabled ? "volume up" : "volume off"}
                                        size="big"
                                        style={{ color: "white" }}
                                        onClick={enableNotificationSound}
                                    />
                                </Flex>
                            </OrderSidebarItem>
                            {orderDisplayState.enablePunchClock && (
                                <OrderSidebarItem
                                    onClick={() => {
                                        onOpenPunchClock();
                                        onCloseDrawer();
                                    }}
                                >
                                    <OrderSideBarHeader text="Stämpelklocka" />
                                </OrderSidebarItem>
                            )}
                            <OrderSidebarItem width="100%">
                                <Flex align="center" justify="space-around">
                                    <Icon
                                        name="minus"
                                        size="big"
                                        style={{ color: "white" }}
                                        onClick={reducingCardStyle}
                                    />
                                    <OrderSideBarHeader as="span" text={`Zoom (${cardStyleZoom}%)`} />
                                    <Icon
                                        name="plus"
                                        size="big"
                                        style={{ color: "white" }}
                                        onClick={zoomingCardStyle}
                                    />
                                </Flex>
                            </OrderSidebarItem>
                            {enableOnlineOrders && !orderDisplayState.pauseOnlineOrders && (
                                <OrderSidebarItem onClick={handleUpdateRushHour}>
                                    <Flex align="center" justify={"center"}>
                                        <OrderSideBarHeader
                                            as={"span"}
                                            text={`${!orderDisplayState.rushHour ? "Starta" : "Stoppa"} rusningstid`}
                                        />
                                        <Box
                                            as={!orderDisplayState.rushHour ? IoMdPlay : IoMdPause}
                                            size="30px"
                                            color="white"
                                            ml={3}
                                        />
                                    </Flex>
                                </OrderSidebarItem>
                            )}
                            {enableHomeDelivery && (
                                <OrderSidebarItem onClick={handleUpdateHomeDelivery}>
                                    <Flex align="center" justify={"center"}>
                                        <OrderSideBarHeader
                                            as={"span"}
                                            text={`${
                                                orderDisplayState.pauseHomeDelivery ? "Starta" : "Pausa"
                                            } Hemleverans`}
                                        />
                                        <Box
                                            as={orderDisplayState.pauseHomeDelivery ? IoMdPlay : IoMdPause}
                                            size="30px"
                                            color="white"
                                            ml={3}
                                        />
                                    </Flex>
                                </OrderSidebarItem>
                            )}
                            {enableOnlineOrders && (
                                <>
                                    <OrderSidebarItem
                                        onClick={() =>
                                            handleUpdateOnlineServices(data.getThirdPartyDeliveryServiceSettings)
                                        }
                                    >
                                        <Flex align="center" justify={"center"}>
                                            <OrderSideBarHeader
                                                as={"span"}
                                                text={
                                                    hasThirdPartyServices
                                                        ? "Pausa tjänster"
                                                        : `${
                                                              orderDisplayState.pauseOnlineOrders ? "Starta" : "Pausa"
                                                          } online`
                                                }
                                            />
                                            {!hasThirdPartyServices && (
                                                <Box
                                                    as={orderDisplayState.pauseOnlineOrders ? IoMdPlay : IoMdPause}
                                                    size="30px"
                                                    color="white"
                                                    ml={3}
                                                />
                                            )}
                                        </Flex>
                                    </OrderSidebarItem>
                                    <OrderSidebarItem onClick={handleEnableEatHere}>
                                        <OrderSideBarHeader
                                            text={`${orderDisplayState.enableEatHere ? "Stoppa" : "Tillåt"} äta här`}
                                        />
                                    </OrderSidebarItem>
                                </>
                            )}
                            <OrderSidebarItem
                                onClick={() => {
                                    toggleSelectedState("openHistoryModal");
                                    onCloseDrawer();
                                }}
                            >
                                <OrderSideBarHeader text="Orderhistorik" />
                            </OrderSidebarItem>
                            <OrderSidebarItem
                                onClick={() => {
                                    openManageTemporariesModal();
                                    onCloseDrawer();
                                }}
                            >
                                <OrderSideBarHeader text="Hantera Slut" />
                            </OrderSidebarItem>
                            <OrderSidebarItem
                                onClick={() => {
                                    openModal(modalNames.ORDER_ADVANCED_SETTINGS_MODAL, {
                                        orderDisplayState,
                                        toggleSelectedState,
                                        shopId: id,
                                        shopSettings: {
                                            kitchenPrinterEnabled,
                                            enableDoneCookingStatus,
                                            enableDonePreppingStatus,
                                            enableCatering
                                        },
                                        openHideRefCategoryModal
                                    });
                                    onCloseDrawer();
                                }}
                            >
                                <OrderSideBarHeader text="Avancerade inställningar" />
                            </OrderSidebarItem>
                            <OrderSidebarItem onClick={onCloseDrawer}>
                                <OrderSideBarHeader text="Stäng" />
                            </OrderSidebarItem>
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Box>
    );
};
