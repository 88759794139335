import React from "react";
import { FaConciergeBell } from "@react-icons/all-files/fa/FaConciergeBell";

import { Header, Image, Stack, ImageProps, Box } from "Atoms";
import { BoltLogoPng, WoltLogoPng } from "Assets";
import { OrderDisplayContext } from "../orderDisplayTypes";

type ThirdPartyImageProps = {
    key: string;
    src: string;
} & ImageProps;

const ThirdPartyImage = ({ key, src, ...rest }: ThirdPartyImageProps) => (
    <Image w="150px" h="auto" objectFit="contain" key={key} src={src} {...rest} />
);

type PausedFooterProps = {
    pausedServices: OrderDisplayContext["pausedServices"];
};

export const PausedFooter: React.FC<PausedFooterProps> = ({ pausedServices }) => {
    if (!pausedServices.length) {
        return null;
    }

    const serviceNameToIconMap = {
        QOPLA: (
            <Header key="qopla" fontFamily="qopla" fontWeight="400" m={0}>
                Q
            </Header>
        ),
        UBEREATS: (
            <Header key="uber" color="#3acb72" fontSize="5xl" m={0} fontWeight="900">
                u
            </Header>
        ),
        BOLT: <ThirdPartyImage key="bolt" src={BoltLogoPng} />,
        WOLT: <ThirdPartyImage key="wolt" src={WoltLogoPng} />,
        FOODORA: <Box key="foodora" as={FaConciergeBell} size="3rem" />
    };

    const elements = pausedServices.map(service => serviceNameToIconMap[service.serviceName]);
    const minutesLeft = pausedServices[0].minutes;

    const isOnlyQopla = pausedServices.length === 1 && pausedServices.find(service => service.serviceName === "QOPLA");

    return (
        <Stack isInline w="100%" minH="50px" justify="center" align="center" zIndex={9999} bg="#c77b12" color="white">
            {isOnlyQopla ? (
                <Header mb={0} mr={3}>
                    ONLINEBESTÄLLNING ÄR PAUSAD {minutesLeft !== null ? `, ${minutesLeft} MIN KVAR` : ""}
                </Header>
            ) : (
                <>
                    <Header mb={0} mr={3}>
                        Pausade tjänster:
                    </Header>
                    <Stack isInline stretch={4} align="center" mr={3}>
                        {elements}
                    </Stack>
                    {minutesLeft && <Header m={0}>{minutesLeft} min</Header>}
                </>
            )}
        </Stack>
    );
};
