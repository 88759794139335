import React from "react";
import { Formik, Form } from "formik-next";
import * as yup from "yup";

import { NewButton as Button, Flex } from "Atoms";
import { Modal, ModalHeader, ModalBody, ModalCloseBtn, ModalActions, FormInput } from "Molecules";
import { IModalContext, Languagekey, useLanguage } from "Providers";
import { modalNames } from "Constants";
import { successNotification } from "TempUtils";

type Props = {
    mutation: any;
    orderId: string;
    orderEmail: string;
    openFrom: "backOffice" | "expressOnline";
};

const translationKeys = {
    expressOnline: {
        headerKey: "receipt",
        submitButtonKey: "sendReceipt",
        notificationKey: "receiptHasBeenSentTo"
    },
    backOffice: {
        headerKey: "receiptCopy",
        submitButtonKey: "sendCopy",
        notificationKey: "receiptCopyHasBeenSentTo"
    }
};

export const OnlineOrderResendConfirmationEmailModal: React.FC<IModalContext<Props>> = ({
    closeModal,
    modalContent
}) => {
    const { translate } = useLanguage();

    const onCloseModal = () => closeModal(modalNames.RESEND_CONFIRMATION_EMAIL_MODAL);

    const { headerKey, submitButtonKey, notificationKey } = translationKeys[modalContent.openFrom];

    const validationSchema = yup.object().shape({
        email: yup.string().email(translate("formErrorValidEmail")).required(translate("requiredError"))
    });

    const initialValues = {
        email: modalContent.orderEmail || ""
    };

    return (
        <Modal open={true} onClose={onCloseModal}>
            <ModalCloseBtn onClick={onCloseModal} />
            <ModalHeader>{translate(headerKey as Languagekey)}</ModalHeader>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async values => {
                    await modalContent.mutation({
                        variables: {
                            orderId: modalContent.orderId,
                            orderEmail: values.email
                        }
                    });
                    onCloseModal();
                    successNotification(`${translate(notificationKey as Languagekey)}: ${values.email}`, 2800);
                }}
            >
                {({ submitForm }) => (
                    <>
                        <ModalBody>
                            <Flex direction="column">
                                <Form>
                                    <FormInput name="email" formLabel={translate("emailAlt")} fullWidth />
                                </Form>
                            </Flex>
                        </ModalBody>
                        <ModalActions>
                            <Button onClick={submitForm} fullWidth themeColor="green">
                                {translate(submitButtonKey as Languagekey)}
                            </Button>
                        </ModalActions>
                    </>
                )}
            </Formik>
        </Modal>
    );
};
