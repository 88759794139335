import React from "react";
import { Icon } from "semantic-ui-react";
import moment from "moment";

import { Flex, Text, Box, NewDivider } from "Atoms";
import { FaConciergeBell } from "@react-icons/all-files/fa/FaConciergeBell";
import { PosTypes, ThirdPartyDeliveryType, thirdPartyDeliveryNames } from "Constants";
import { formatSwedishPhoneNumber, checkPhoneNumberExists } from "Utils";

export const ContactInformation = ({
    contactInformation,
    onlineOrderNeedsConfirmation,
    onlineDeclineTimeLeft,
    isHomeDelivery,
    isCatering,
    showCardColors,
    discountName,
    isCateringConfirmed,
    orderType,
    thirdPartyCode,
    webOrderType,
    ...rest
}) => {
    const timeLeftColor = onlineDeclineTimeLeft < 60 ? "red.600" : "";
    const format = isCatering ? "HH:mm:ss" : "mm:ss";
    const hasPhoneNumber = checkPhoneNumberExists(contactInformation.phoneNumber);
    return (
        <div
            className={`OD-card-contactInformation ${onlineOrderNeedsConfirmation ? "notification-blink" : ""}`}
            style={{
                fontSize: rest.fontSize ? rest.fontSize : ""
            }}
        >
            {thirdPartyCode && (
                <Box my={0}>
                    <Flex direction="row" fontWeight={showCardColors ? "bold" : "normal"} align="center" mb={4} ml={2}>
                        {orderType === ThirdPartyDeliveryType.FOODORA && (
                            <Box
                                as={FaConciergeBell}
                                size="1.2em"
                                color={showCardColors ? "black" : "white"}
                                mr="1.1em"
                            />
                        )}
                        {orderType === ThirdPartyDeliveryType.UBEREATS && (
                            <Box mr="0.6em" pb="0.6rem" lineHeight=" 0.4" fontSize="2em" fontWeight="900">
                                u
                            </Box>
                        )}
                        <Text textTransform="capitalize" mb={0}>
                            {thirdPartyDeliveryNames[orderType]}
                        </Text>
                        <Text>: {thirdPartyCode}</Text>
                    </Flex>
                    <NewDivider color="gray.400" gridColumn="span 2" border="" opacity={0.35} mb={3} />
                </Box>
            )}
            <Flex align="center">
                <Flex mr={4}>
                    <Icon name="address book outline" size="large" />
                </Flex>
                <Flex direction="column" fontWeight={showCardColors ? "bold" : "normal"}>
                    {discountName && <Text mb={1}>Rabattkod: {discountName}</Text>}
                    {webOrderType === PosTypes.EXPRESS && <Text mb={1}>Expresskassan</Text>}
                    {contactInformation.name && <Text mb={1}>Namn: {contactInformation.name}</Text>}
                    {hasPhoneNumber && (
                        <Text mb={1}>Telefon: {formatSwedishPhoneNumber(contactInformation.phoneNumber)}</Text>
                    )}
                    {isHomeDelivery && (
                        <>
                            {contactInformation.addressLine && (
                                <Text mb={1}>Address: {contactInformation.addressLine}</Text>
                            )}
                            {contactInformation.postCode > 0 && (
                                <Text mb={1}>Postnummer: {contactInformation.postCode}</Text>
                            )}
                            {contactInformation.city && <Text mb={1}>Ort: {contactInformation.city}</Text>}
                            {contactInformation.doorCode && <Text mb={1}>Portkod: {contactInformation.doorCode}</Text>}
                            {contactInformation.floorNumber && (
                                <Text mb={1}>Vån: {contactInformation.floorNumber}</Text>
                            )}
                        </>
                    )}
                </Flex>
            </Flex>
            {!isCateringConfirmed || onlineOrderNeedsConfirmation ? (
                <Flex mt={4}>
                    <Text as="span" mr={1}>
                        Tid kvar:{" "}
                    </Text>
                    <Text as="span" color={timeLeftColor}>
                        {moment().startOf("day").seconds(onlineDeclineTimeLeft).format(format)}
                    </Text>
                </Flex>
            ) : null}
        </div>
    );
};
