import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { Button, Icon } from "semantic-ui-react";
import { FaShippingFast } from "@react-icons/all-files/fa/FaShippingFast";
import { FaShoppingBag } from "@react-icons/all-files/fa/FaShoppingBag";
import { FaUtensils } from "@react-icons/all-files/fa/FaUtensils";
import { FaClock } from "@react-icons/all-files/fa/FaClock";
import { FaBolt } from "@react-icons/all-files/fa/FaBolt";
import { FaConciergeBell } from "@react-icons/all-files/fa/FaConciergeBell";
import { deviceDetect } from "react-device-detect";

import {
    modalNames,
    onlineOrderStatus,
    AUTO_DECLINE_MINUTES,
    ThirdPartyDeliveryType,
    thirdPartyDeliveryNames
} from "Constants";
import { formatDateToLocal, swedishDayOfWeek } from "Utils";
import { useInterval } from "Hooks";
import { Header, Flex, NewDivider, Text, Box, NewButton } from "Atoms";
import { Modal, ModalHeader, ModalBody, ModalActions } from "Molecules";
import { OrderProducts, OrderComment } from "./components";
import { ContactInformation } from "./components/ContactInformation";
import { confirmOrCancelNotification } from "../../utils";
import { formatDateToBackendUTCString } from "../../../admin/utils/TextFormat";
import { WebOrderType } from "../../../generated/graphql";
import { RiComputerFill } from "@react-icons/all-files/ri/RiComputerFill";

const ConfirmOrderPickupTimeModal = ({ modalContent, closeModal }) => {
    const { onlineOrderPickupTimes, onlineOrderPickupTimesPostpone } = modalContent.onlineSettings;
    const [selectedTime, setSelectedTime] = useState(
        onlineOrderPickupTimesPostpone.length > 0 && modalContent.kitchenOrder.pickupTime ? 0 : null
    );

    const [kitchenOrderPickupTime, _] = useState(
        modalContent.kitchenOrder.pickupTime ? moment.utc(modalContent.kitchenOrder.pickupTime) : moment.utc()
    );

    const [additionalMins, setAdditionalMins] = useState(0);
    const [declineTimeLeft, setDeclineTimeLeft] = useState(modalContent.onlineDeclineTimeLeft);
    const [innerContentOffsetHeight, setInnerContentOffsetHeight] = useState(0);

    const homeDeliveryTimes = [20, 30, 45, 60, 70, 80, 90, 120];

    const headerRef = useRef(null);
    const footerRef = useRef(null);

    useEffect(() => {
        if (!headerRef.current || !footerRef.current) return;
        setInnerContentOffsetHeight(
            headerRef.current.getBoundingClientRect().height + footerRef.current.getBoundingClientRect().height + 2
        );
    }, [headerRef, footerRef]);

    useInterval(() => {
        const timeDiff = Math.max(
            AUTO_DECLINE_MINUTES * 60 -
                Math.max(moment.utc().unix() - moment.utc(modalContent.kitchenOrder.createdAt).unix(), 0),
            0
        );
        setDeclineTimeLeft(timeDiff);
    }, 5000);

    const handleCloseModal = time => {
        const pickupTime = kitchenOrderPickupTime.clone().add(time, "minutes");
        const formattedTime = formatDateToBackendUTCString(pickupTime);

        modalContent.handleCloseConfirmPickupModal(formattedTime);
    };

    const pickupTimeButtons = (setAdditionalMins, times = []) => {
        const formattedTimes = [
            ...times
                .filter(t => (!!modalContent.kitchenOrder.thirdPartyDeliveryType && !isUberEats ? t >= 25 : true))
                .map(t => ({
                    minutes: t,
                    time: formatDateToLocal(kitchenOrderPickupTime.clone().add(t, "minutes"), "HH:mm")
                })),
            ...(modalContent.kitchenOrder.pickupTime
                ? [
                      {
                          minutes: 0,
                          time: formatDateToLocal(kitchenOrderPickupTime, "HH:mm")
                      }
                  ]
                : [])
        ];
        return formattedTimes
            .sort((a, b) => a.minutes - b.minutes)
            .map((item, idx) => {
                const isSelectedTime = selectedTime === item.minutes;
                return (
                    <NewButton
                        key={`${item.minutes}--${idx}`}
                        height="120px"
                        width="auto"
                        flex={["1 0 120px", "0 0 30%"]}
                        fontSize="1.5rem"
                        fontWeight={700}
                        themeColor=""
                        color={isSelectedTime ? "#fff" : ""}
                        backgroundColor={isSelectedTime ? "#73bd86" : "#e3e3e3"}
                        padding={1}
                        margin={1}
                        onClick={() => {
                            setSelectedTime(item.minutes);
                            setAdditionalMins(item.minutes);
                        }}
                    >
                        <Box>
                            {item.minutes !== 0 ? (
                                <Box>{item.minutes}</Box>
                            ) : (
                                <Icon style={{ margin: 0 }} name="check" />
                            )}
                            <Box>{`(${item.time})`}</Box>
                        </Box>
                    </NewButton>
                );
            });
    };

    const modalHeaderColor = modalContent.kitchenOrder.pickupTime ? "rgba(0, 0, 255, 0.85)" : "#21ba45";
    const isThirdPartyDelivery =
        modalContent.kitchenOrder.thirdPartyDeliveryType ===
        ThirdPartyDeliveryType[modalContent.kitchenOrder.orderType];

    const isUberEats = modalContent.kitchenOrder.thirdPartyDeliveryType === ThirdPartyDeliveryType.UBEREATS;
    const confirmDenyButtonsDisabled = declineTimeLeft <= 0;
    const isPickupToday =
        kitchenOrderPickupTime.clone().add(additionalMins, "minutes").format("YYYYMMDD") ===
        moment().utc().format("YYYYMMDD");

    const isPickupWithinOneHour =
        !modalContent.kitchenOrder.pickupTime ||
        moment.utc(modalContent.kitchenOrder.pickupTime).isBetween(moment.utc(), moment.utc().add(1, "hour"));

    const deviceInformation = deviceDetect();
    const timeLeftColor = declineTimeLeft < 60 ? "red.600" : "";
    const isAlwaysAllowPostponePickupTime = modalContent.onlineSettings.alwaysAllowPostponePickupTime;
    const isExpress = modalContent.kitchenOrder.webOrderType === WebOrderType.Express;

    return (
        <Modal
            borderRadius="lg"
            maxWidth={{
                base: "90vw",
                xl: deviceInformation.isMobile ? "90vw" : "65vw"
            }}
            overflow="hidden"
            height="90vh"
            dimmer="inverted"
            open={true}
            onClose={() => closeModal(modalNames.CONFIRM_ORDER_PICKUP_TIME_MODAL)}
            userSelect="none"
        >
            <ModalHeader
                justifyContent="space-between"
                padding={0}
                backgroundColor={modalHeaderColor}
                position="sticky"
                height="85px"
                display="flex"
            >
                {!isThirdPartyDelivery && (
                    <Flex
                        alignItems="center"
                        backgroundColor={
                            modalContent.kitchenOrder.takeAway && !modalContent.kitchenOrder.homeDelivery
                                ? "#db2828"
                                : modalContent.kitchenOrder.homeDelivery
                                ? "#a333c8"
                                : ""
                        }
                        color="base"
                        width="85px"
                        borderRadius="0.5rem 0 0 0"
                        justifyContent="center"
                    >
                        <Box
                            as={
                                modalContent.kitchenOrder.takeAway && !modalContent.kitchenOrder.homeDelivery
                                    ? FaShoppingBag
                                    : modalContent.kitchenOrder.homeDelivery
                                    ? FaShippingFast
                                    : FaUtensils
                            }
                            size="3rem"
                        />
                    </Flex>
                )}
                {ThirdPartyDeliveryType[modalContent.kitchenOrder.orderType] === ThirdPartyDeliveryType.FOODORA && (
                    <Flex
                        alignItems="center"
                        backgroundColor="#c60d5c"
                        color="base"
                        width="85px"
                        justifyContent="center"
                    >
                        <Box as={FaConciergeBell} size="3rem" />
                    </Flex>
                )}
                {isUberEats && (
                    <Flex alignItems="center" backgroundColor="black" width="85px" justifyContent="center">
                        <Box color="#3acb72" m={0} pb="1.2rem" lineHeight=" 0" fontSize="5rem" fontWeight="900">
                            u
                        </Box>
                    </Flex>
                )}
                <Flex
                    padding={4}
                    width="100%"
                    align="center"
                    justifyContent="space-between"
                    ref={headerRef}
                    color="base"
                >
                    <Flex alignItems="center" flex="1">
                        <Box
                            as={!!modalContent.kitchenOrder.pickupTime ? FaClock : isExpress ? RiComputerFill : FaBolt}
                            size="3rem"
                            marginRight={2}
                        />
                        <Header as="h1" size="2xl" fontWeight="normal" margin="0 0 0.25rem 0" flex="1">
                            {modalContent.kitchenOrder.pickupTime
                                ? !isPickupToday
                                    ? `${
                                          swedishDayOfWeek.find(
                                              day => day.momentDayNo === kitchenOrderPickupTime.clone().day()
                                          ).swe
                                      } ${formatDateToLocal(
                                          kitchenOrderPickupTime.clone().add(additionalMins, "minutes"),
                                          "HH:mm"
                                      )}`
                                    : `Idag ${formatDateToLocal(
                                          kitchenOrderPickupTime.clone().add(additionalMins, "minutes"),
                                          "HH:mm"
                                      )}`
                                : isExpress
                                ? "EXPRESSKASSAN"
                                : "Snarast"}
                        </Header>
                        <Flex whiteSpace="pre-wrap" alignItems="center" fontSize="4xl" marginRight={4}>
                            <Text margin={0} fontWeight="400">
                                {"Tid kvar: "}
                            </Text>
                            <Text margin={0} color={timeLeftColor} fontWeight="bold">
                                {moment().startOf("day").seconds(declineTimeLeft).format("mm:ss")}
                            </Text>
                        </Flex>
                    </Flex>
                    <Button
                        color="red"
                        style={{ margin: 0, height: "4rem" }}
                        content={<Icon style={{ margin: 0 }} name="close" />}
                        onClick={() => closeModal(modalNames.CONFIRM_ORDER_PICKUP_TIME_MODAL)}
                    />
                </Flex>
            </ModalHeader>
            <ModalBody style={{ overflowY: "auto", maxHeight: `calc(90vh - ${innerContentOffsetHeight}px)` }}>
                <Flex
                    flexDirection={["column", "column", "column", "row"]}
                    overflowY={["auto", "auto", "auto", "visible"]}
                    position="relative"
                >
                    {confirmDenyButtonsDisabled && (
                        <Flex
                            position="absolute"
                            top={0}
                            bottom={0}
                            left={0}
                            right={0}
                            justifyContent="center"
                            alignItems="center"
                            zIndex={1}
                        >
                            <Header transform="rotate(-45deg)" opacity={0.6} size="3xl" color="red.500">
                                ORDER MISSAD
                            </Header>
                        </Flex>
                    )}
                    <Flex
                        height="auto"
                        flexDirection="column"
                        width={["100%", "100%", "100%", "50%"]}
                        maxHeight={["auto", "auto", "auto", `calc((90vh - 3rem) - ${innerContentOffsetHeight}px)`]}
                        overflowY={["visible", "visible", "visible", "auto"]}
                        paddingX={4}
                    >
                        {(isPickupWithinOneHour || isAlwaysAllowPostponePickupTime) &&
                        (!isThirdPartyDelivery || isUberEats) ? (
                            <>
                                <Flex height="auto">
                                    <Text margin={0} fontSize="3xl">
                                        {isUberEats
                                            ? "Hämtas av kurir senaste kl:"
                                            : modalContent.kitchenOrder.homeDelivery
                                            ? "Levereras senast om "
                                            : modalContent.kitchenOrder.pickupTime
                                            ? "Skjut upp med "
                                            : "Kan hämtas upp om "}
                                        {!isUberEats && "antal minuter"}
                                    </Text>
                                </Flex>
                                <Flex flexWrap="wrap" height="auto">
                                    {pickupTimeButtons(
                                        setAdditionalMins,
                                        modalContent.kitchenOrder.homeDelivery && !isUberEats
                                            ? homeDeliveryTimes
                                            : !modalContent.kitchenOrder.pickupTime || isUberEats
                                            ? onlineOrderPickupTimes
                                            : onlineOrderPickupTimesPostpone
                                    )}
                                </Flex>
                                <NewDivider color="gray.200" width="auto" marginY={4} />
                            </>
                        ) : null}
                        {(modalContent.kitchenOrder.online || modalContent.kitchenOrder.postponePayment) &&
                            modalContent.kitchenOrder.contactInformation && (
                                <ContactInformation
                                    onlineDeclineTimeLeft={declineTimeLeft}
                                    contactInformation={modalContent.kitchenOrder.contactInformation}
                                    isHomeDelivery={modalContent.kitchenOrder.homeDelivery}
                                    webOrderType={modalContent.kitchenOrder.webOrderType}
                                    showCardColors
                                    discountName={modalContent.kitchenOrder.discountName}
                                    thirdPartyCode={modalContent.kitchenOrder.thirdPartyCode}
                                    orderType={modalContent.kitchenOrder.orderType}
                                    fontSize="1.5rem"
                                />
                            )}
                    </Flex>
                    <Flex
                        flexDirection="column"
                        width={["100%", "100%", "100%", "50%"]}
                        maxHeight={["auto", "auto", "auto", `calc((90vh - 3rem) - ${innerContentOffsetHeight}px)`]}
                        overflowY={["visible", "visible", "visible", "auto"]}
                        paddingX={4}
                    >
                        <Header as="h2" textAlign="center" size="2xl" marginBottom={0}>
                            {modalContent.kitchenOrder.takeAway && !modalContent.kitchenOrder.homeDelivery
                                ? "Ta med"
                                : modalContent.kitchenOrder.homeDelivery
                                ? isThirdPartyDelivery
                                    ? `${thirdPartyDeliveryNames[modalContent.kitchenOrder.orderType]} leverans`
                                    : "Hemleverans"
                                : "Äta här"}
                        </Header>
                        <OrderComment showCardColors comment={modalContent.kitchenOrder.comment} fontSize="1.5rem" />
                        <OrderProducts
                            showCardColors
                            orderProducts={modalContent.kitchenOrder.kitchenOrderProducts}
                            refCatHashMap={modalContent.refCatHashMap}
                            productDescriptionHashMap={modalContent.productDescriptionHashMap}
                            fontSize="1.25rem"
                        />
                    </Flex>
                </Flex>
            </ModalBody>
            <ModalActions style={{ padding: 0 }}>
                <Flex paddingX={6} paddingY={4} width="100%" align="center" ref={footerRef}>
                    <Flex direction="row" width="100%">
                        {!confirmDenyButtonsDisabled ? (
                            <>
                                <Button
                                    style={{
                                        height: "50px",
                                        flex: "1 1 15%"
                                    }}
                                    color="red"
                                    size="large"
                                    icon="close"
                                    labelPosition="right"
                                    content="Neka"
                                    onClick={async () => {
                                        const { value } = await confirmOrCancelNotification(
                                            "Neka order?",
                                            "Är du säker på att du vill neka denna order? Pengar kommer att återbetalas till kund."
                                        );
                                        if (value) {
                                            modalContent.handleUpdateOnlineOrderStatus({
                                                variables: {
                                                    orderId: modalContent.kitchenOrder.orderId,
                                                    kitchenOrderId: modalContent.kitchenOrder.id,
                                                    orderStatus: onlineOrderStatus.DENIED
                                                }
                                            });
                                            closeModal(modalNames.CONFIRM_ORDER_PICKUP_TIME_MODAL);
                                        }
                                    }}
                                />
                                <Button
                                    style={{
                                        height: "50px",
                                        flex: "1 1 85%"
                                    }}
                                    color="green"
                                    size="large"
                                    icon="check"
                                    labelPosition="right"
                                    content={`Bekräfta (${moment()
                                        .startOf("day")
                                        .seconds(declineTimeLeft)
                                        .format("mm:ss")})`}
                                    disabled={isThirdPartyDelivery || !isUberEats ? false : selectedTime === null}
                                    onClick={() => {
                                        handleCloseModal(selectedTime);
                                    }}
                                />
                            </>
                        ) : (
                            <Button
                                style={{
                                    height: "50px",
                                    width: "100%"
                                }}
                                color="red"
                                size="large"
                                icon="close"
                                labelPosition="right"
                                content="Stäng"
                                onClick={() => {
                                    closeModal(modalNames.CONFIRM_ORDER_PICKUP_TIME_MODAL);
                                }}
                            />
                        )}
                    </Flex>
                </Flex>
            </ModalActions>
        </Modal>
    );
};

export default ConfirmOrderPickupTimeModal;
