import { Box, Button, Collapsable, Flex, FormLabel, Stack } from "Atoms";
import { Modal, ModalActions, ModalBody, ModalCloseBtn, ModalHeader, RadioButtonGroup } from "Molecules";
import React, { useState } from "react";
import { THIRD_PARTY_DELIVERY_TYPE_IN_SWEDISH, modalNames } from "Constants";

import { IModalContext } from "Providers";
import { IoMdPause } from "@react-icons/all-files/io/IoMdPause";
import { IoMdPlay } from "@react-icons/all-files/io/IoMdPlay";
import { OrderDisplayContext } from "../orderDisplayTypes";
import { ThirdPartyDeliveryService } from "Types";

const CustomRadioButton = ({ value, ...rest }: any) => {
    return <Button value={value} role="radio" {...rest} />;
};

type Props = {
    thirdPartyDeliveryServices: ThirdPartyDeliveryService[];
    pauseOnlineOrders: boolean;
    saveDeliverySettings: any;
    pausedServices: OrderDisplayContext["pausedServices"];
};

type Option = {
    [key: string]: {
        isActive: boolean;
        minutes: string;
    };
};

const getInitialOptions = (
    thirdPartyDeliveryServices: ThirdPartyDeliveryService[],
    pausedServices: OrderDisplayContext["pausedServices"],
    pauseOnlineOrders: boolean,
    minutes?: string
) => {
    const qoplaOption = { thirdPartyDeliveryType: "QOPLA", enableService: !pauseOnlineOrders };

    return [qoplaOption, ...thirdPartyDeliveryServices].reduce<Option>((acc, next) => {
        const foundPausedService = pausedServices.find(service => service.serviceName === next.thirdPartyDeliveryType);
        acc[next.thirdPartyDeliveryType] = {
            isActive: next.enableService,
            minutes: minutes ? minutes : foundPausedService ? foundPausedService.minutes : ""
        };

        return acc;
    }, {});
};

export const OnlineServicesModal: React.FC<IModalContext<Props>> = ({
    modalContent: { thirdPartyDeliveryServices, pauseOnlineOrders, saveDeliverySettings, pausedServices },
    closeModal
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [options, setOptions] = useState<Option>(
        getInitialOptions(thirdPartyDeliveryServices, pausedServices, pauseOnlineOrders)
    );
    const [minutes, setMinutes] = useState("30");

    const onToggleEnableForService = (service: string, isActive: boolean) => {
        setOptions(() => ({
            ...options,
            [service]: {
                isActive,
                minutes: !isActive ? minutes : ""
            }
        }));
    };

    const onSetServicePauseMins = (minutes: string) => {
        const newOptions = Object.entries(options).reduce<Option>((acc, [key, value]) => {
            acc[key] = {
                isActive: value.isActive,
                minutes: !value.isActive ? minutes : ""
            };
            return acc;
        }, {});

        setMinutes(minutes);
        setOptions(newOptions);
    };

    const formatDeliverySettings = (options: any) => {
        const { QOPLA, ...rest } = options;
        const _thirdPartyDeliveryServices = thirdPartyDeliveryServices.map(service => ({
            ...service,
            enableService: rest[service.thirdPartyDeliveryType].isActive
        }));

        return {
            thirdPartyDeliveryServices: _thirdPartyDeliveryServices,
            onlineServiceTimeouts: options
        };
    };

    const optionValues = Object.values(options);
    const activeServicesCount = optionValues.filter(option => option.isActive).length;
    const hasPausedOptions = optionValues.length !== activeServicesCount;

    return (
        <Modal open onClose={() => closeModal(modalNames.ONLINE_SERVICE_MODAL)}>
            <ModalHeader>
                Onlinetjänster
                <ModalCloseBtn />
            </ModalHeader>
            <ModalBody>
                <FormLabel>Aktiva tjänster: {activeServicesCount}</FormLabel>
                <Stack isInline stretch={8}>
                    {Object.entries(options).map(([service, info]) => {
                        const isQopla = service === "QOPLA";
                        const Icon = info.isActive ? IoMdPause : IoMdPlay;

                        return (
                            <Button
                                key={service}
                                size="2xl"
                                borderRadius="lg"
                                onClick={() => onToggleEnableForService(service, !info.isActive)}
                                borderStyle="solid"
                                borderWidth="3px"
                                borderColor={info.isActive ? "green.500" : "gray.300"}
                                bg={info.isActive ? "green.100" : "white"}
                                flexDirection="column"
                                _hover={{}}
                            >
                                <Box as={Icon} mb={2} />
                                {/*  @ts-ignore */}
                                {isQopla ? "Qopla" : THIRD_PARTY_DELIVERY_TYPE_IN_SWEDISH[service]}
                            </Button>
                        );
                    })}
                </Stack>
                <Box mt={4}>
                    <Collapsable open={hasPausedOptions}>
                        <FormLabel>Hur länge vill ni pausa?</FormLabel>
                        <Flex direction="column">
                            <RadioButtonGroup
                                name="minutes"
                                value={minutes}
                                activeThemeColor="green"
                                inactiveThemeColor="gray"
                                onValueChanged={(value: string) => onSetServicePauseMins(value)}
                                stretch={4}
                            >
                                <CustomRadioButton value="30">30 min</CustomRadioButton>
                                <CustomRadioButton value="60">60 min</CustomRadioButton>
                            </RadioButtonGroup>
                        </Flex>
                    </Collapsable>
                </Box>
            </ModalBody>
            <ModalActions>
                <Button
                    themeColor="green"
                    fullWidth
                    isLoading={isSubmitting}
                    isDisabled={hasPausedOptions && !minutes}
                    size="lg"
                    onClick={() => {
                        setIsSubmitting(true);
                        saveDeliverySettings(formatDeliverySettings(options));
                    }}
                >
                    Spara
                </Button>
            </ModalActions>
        </Modal>
    );
};
