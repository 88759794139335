import React, { useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import { KitchenOrderStatus } from "Constants";
import "../orderDisplay.scss";
import { confirmNotification, ScaledDiv } from "TempUtils";

export const DynamicButton = ({ doFunction, text, showCardColors, iconName, undoButton, disabled = false }) => {
    let className = undoButton ? "OD-undo-button" : "OD-green-button";
    if (showCardColors) {
        className = undoButton ? "OD-undo-color-button" : "OD-ready-color-button";
    }
    return (
        <ScaledDiv initialScale={1.0} pressedScale={0.9}>
            <Button
                disabled={disabled}
                size="large"
                className={className}
                icon
                onClick={e => {
                    e.stopPropagation();
                    if (undoButton) {
                        confirmNotification("Ångra status", "Vill du gå tillbaka till föregående status?", "Ja").then(
                            result => {
                                if (result.value) {
                                    doFunction();
                                }
                            }
                        );
                    } else {
                        doFunction();
                    }
                }}
            >
                {iconName && <Icon name={iconName} />}
                {text}
            </Button>
        </ScaledDiv>
    );
};

export const OrderItemButton = ({
    orderStatus,
    changeStatus,
    orderId,
    enableDoneCookingStatus,
    enableDonePreppingStatus,
    showCardColors,
    viewLoadingMsg,
    lastMileHasError,
    handleFilterUnfinishedKitchenOrders
}) => {
    const [disabled, setDisabled] = useState(false);
    if (lastMileHasError) {
        return null;
    } else if (!viewLoadingMsg) {
        if (orderStatus === KitchenOrderStatus.DONE_COOKING) {
            return (
                <DynamicButton
                    disabled={disabled}
                    showCardColors={showCardColors}
                    doFunction={() => {
                        setDisabled(true);
                        changeStatus(orderId, KitchenOrderStatus.READY_FOR_PICKUP);
                    }}
                    text="Redo"
                />
            );
        } else if (orderStatus === KitchenOrderStatus.DONE_PREPPING) {
            return (
                <DynamicButton
                    showCardColors={showCardColors}
                    doFunction={() => {
                        setDisabled(true);
                        changeStatus(
                            orderId,
                            enableDoneCookingStatus
                                ? KitchenOrderStatus.DONE_COOKING
                                : KitchenOrderStatus.READY_FOR_PICKUP
                        );
                    }}
                    text={enableDoneCookingStatus ? "Färdiglagad" : "Redo"}
                />
            );
        } else if (orderStatus === KitchenOrderStatus.PROCESSING || orderStatus === KitchenOrderStatus.COOKING) {
            let constToPass, buttonText;
            // must be in this order
            if (enableDonePreppingStatus) {
                buttonText = "Färdigpreppad";
                constToPass = KitchenOrderStatus.DONE_PREPPING;
            } else if (enableDoneCookingStatus) {
                buttonText = "Färdiglagad";
                constToPass = KitchenOrderStatus.DONE_COOKING;
            } else {
                buttonText = "Redo";
                constToPass = KitchenOrderStatus.READY_FOR_PICKUP;
            }
            return (
                <DynamicButton
                    disabled={disabled}
                    showCardColors={showCardColors}
                    doFunction={() => {
                        setDisabled(true);
                        changeStatus(orderId, constToPass);
                    }}
                    text={buttonText}
                />
            );
        } else if (orderStatus === KitchenOrderStatus.READY_FOR_PICKUP) {
            return (
                <DynamicButton
                    showCardColors={showCardColors}
                    doFunction={() => {
                        setDisabled(true);
                        changeStatus(orderId, KitchenOrderStatus.DELIVERED);
                    }}
                    text="Upphämtad"
                />
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
};

export const RevertStatusButton = ({
    orderStatus,
    changeStatus,
    orderId,
    enableDoneCookingStatus,
    enableDonePreppingStatus,
    showCardColors,
    text
}) => {
    let newStatus = null;
    switch (orderStatus) {
        case KitchenOrderStatus.DELIVERED:
            newStatus = KitchenOrderStatus.READY_FOR_PICKUP;
            break;
        case KitchenOrderStatus.READY_FOR_PICKUP:
            if (enableDoneCookingStatus) {
                newStatus = KitchenOrderStatus.DONE_COOKING;
            } else if (enableDonePreppingStatus) {
                newStatus = KitchenOrderStatus.DONE_PREPPING;
            } else {
                newStatus = KitchenOrderStatus.PROCESSING;
            }
            break;
        case KitchenOrderStatus.DONE_PREPPING:
            newStatus = KitchenOrderStatus.PROCESSING;
            break;
        case KitchenOrderStatus.DONE_COOKING:
            if (enableDonePreppingStatus) {
                newStatus = KitchenOrderStatus.DONE_PREPPING;
            } else {
                newStatus = KitchenOrderStatus.PROCESSING;
            }
            break;
    }
    return newStatus ? (
        <DynamicButton
            undoButton={true}
            showCardColors={showCardColors}
            doFunction={() => changeStatus(orderId, newStatus)}
            iconName="undo"
            text={text}
        />
    ) : null;
};
